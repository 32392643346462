<template>
    <div class="home">
      <div class="top">
      <div class="back" @click="onClickLeft('/news')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
        {{$t('m.ggxq')}}
      </div>
    </div>
      <div class="content"> 
        <div class="stop">
            <div class="title">{{dqyy =='zh-CN'?info.title:info.titleEn}}</div>
            <div class="time">{{info.createDate}}</div>
        </div>

     <div class="card">
        <div class="cont" v-if="dqyy =='zh-CN'" v-html="info.content"></div>
        <div class="cont" v-else v-html="info.contentEn"></div>
    </div>
  
</div>
</div>
</template>
<script>
export default {
  name: "myContracts",
  data() {
    return {
        id:0,
        info:"",
        

    }
  },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en-US";
      }
    },  
  },
  created(){
    this.id=Number(this.$route.query.id)
    this.getInfo()
  },
  methods:{
   async getInfo(){
    let {data: { code, data, message },} = await this.$http.get(this.$api.getOne,{id: this.id});
    if(code==200){
        this.info=data
    }
    },
    onClickLeft(val) {
       this.$router.replace({ path:val})
    },
    changer(e){
        this.current=e
    },
    toFie(e){
        this.fbl=e
    },
    tochanger(e){
        this.flee=e
    },
    tochangers(e){
        this.flees=e
    },
    toShow(e){
        this.type=e
        this.show=true
    },
    toxz(e){
        if(this.type==1){this.info=e}
        if(this.type==2){this.infos=e}
        this.show=false
    },
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
  .top {
background: rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
      .back {
      position: absolute;
    left: 20px;
    width: 50px;
    height: 50px;
    // background: linear-gradient(45deg,#e4005e, #ff0050);
    // border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title{
        background: linear-gradient(234deg,#ffffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }
    .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
      color: #fff;
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }
    .content{
        margin-top: 10px;
        .stop{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            padding:0 48px;
            .title{
            font-size: 38px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
        }
            .time{
                font-size: 24px;
            text-align: center;
            color: #ffffff;
            margin-top: 16px;            
        margin-bottom: 18px;
            }
        }
      
       .card{
        display: flex;
    flex-direction: column;
    border-radius: 30px;
    border: 1px solid rgb(53, 53, 53);
    background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
    position: relative;
    padding: 10px 30px;
    margin: 18px;
    min-height: calc(100vh - 340px);
    .cont{
        font-size: 24px;
            text-align: left;
            color: #ffffff;
    }}
}
}
  </style>
